const roles = {
  admin: ['ERG_ADMIN'],
  analyst: ['ERG_TERCEIRIZADO'],
  coordinator: ['ERG_ADMIN_POSTO'],
  expert: ['ERG_QUALIDADE'],
  supervisor: ['ERG_SUPERVISOR'],
  assistantSupervisor: ['ERG_AUX_SUPERVISOR'],
  archive: ['ERG_ARQUIVO'],
  userErg: ['ERG_SOLICITANTE'],
  userNonAdmin: ['IC_USER', 'ERG_SOLICITANTE'],
  printerOperator: ['ERG_IMPRESSAO'],
};

const rolesPath = [
  {
    path: '/home',
    role: roles.userNonAdmin,
  },
  {
    path: '/profile',
    role: roles.userErg,
  },
  {
    path: '/request-type',
    role: roles.userNonAdmin,
  },
  {
    path: '/request-form',
    role: roles.userNonAdmin,
  },
  {
    path: '/dashboard',
    role: roles.userNonAdmin,
  },
  {
    path: '/local/selection',
    role: roles.userNonAdmin,
  },
  {
    path: '/request-to-other',
    role: roles.userNonAdmin,
  },
  {
    path: '/supervisor',
    role: roles.supervisor,
  },
  {
    path: '/analyst',
    role: roles.analyst,
  },
  {
    path: '/analyst/auth/password',
    role: roles.analyst,
  },
  {
    path: '/admin',
    role: roles.admin,
  },
  {
    path: '/admin/auth/password',
    role: roles.admin,
  },
  {
    path: '/admin/location/data',
    role: roles.admin,
  },
  {
    path: '/admin/location/list',
    role: roles.admin,
  },
  {
    path: '/admin/location/register',
    role: roles.admin,
  },
  {
    path: '/admin/users/list',
    role: roles.admin,
  },
  {
    path: '/admin/users/data',
    role: roles.admin,
  },
  {
    path: '/analyst/checkin',
    role: roles.analyst,
  },
  {
    path: '/analyst/checkin/data',
    role: roles.analyst,
  },
  {
    path: '/analyst/attendance',
    role: roles.analyst,
  },
  {
    path: '/analyst/attendance/data',
    role: roles.analyst,
  },
  {
    path: '/analyst/attendance/anthropometry',
    role: roles.analyst,
  },
  {
    path: '/analyst/attendance/review/data',
    role: roles.analyst,
  },
  {
    path: '/analyst/attendance/resume',
    role: roles.analyst,
  },
  {
    path: '/admin/scheduler',
    role: roles.admin,
  },
  {
    path: '/admin/scheduler/register',
    role: roles.admin,
  },
  {
    path: '/admin/scheduler/availability',
    role: roles.admin,
  },
  {
    path: '/admin/dashboard',
    role: roles.admin,
  },
  {
    path: '/consultation',
    role: [roles.analyst[0], roles.supervisor[0], roles.admin[0]],
  },
  {
    path: '/consultation/data',
    role: [roles.analyst[0], roles.supervisor[0], roles.admin[0]],
  },
  {
    path: '/list-attendance',
    role: [roles.admin[0], roles.supervisor[0]],
  },
];

export default rolesPath;
