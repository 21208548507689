import React, { useEffect } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { FormattedMessage } from 'react-intl';

import contrast from 'assets/img/accessibility/contrast.svg';
import fontPlus from 'assets/img/accessibility/font-plus.svg';
import fontLess from 'assets/img/accessibility/font-less.svg';
import fontDefault from 'assets/img/accessibility/font-normal.svg';

import './accessibilityBar.scss';
import {
  toogleContrast,
  getDefaultContrast,
  decreaseFont,
  getDefaultFontSize,
  increaseFont,
  setDefaultFontSize,
} from '../../../utils/accessibility';
import { DownOutlined } from '@ant-design/icons';

const menu = (
  <Menu className="menu-accessibility">
    <Menu.Item key="1" className="item-accessibility">
      <a
        onClick={() => {}}
        href="https://cearatransparente.ce.gov.br/"
        target="_blank"
        rel="noreferrer"
      >
        Ceará Transparente
      </a>
    </Menu.Item>
    <Menu.Item key="2" className="item-accessibility">
      <a
        onClick={() => {}}
        href="https://cartadeservicos.ce.gov.br/ConsultaCesec/pg_cs_servico.aspx"
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        Carta de serviços do cidadão
      </a>
    </Menu.Item>
    <Menu.Item key="3" className="item-accessibility">
      <a
        onClick={() => {}}
        href="https://cearatransparente.ce.gov.br/portal-da-transparencia/acesso-a-informacao?locale=pt-BR"
        target="_blank"
        rel="noreferrer"
      >
        Lei geral de acesso à informação
      </a>
    </Menu.Item>
    <Menu.Item key="4" className="item-accessibility">
      <a
        onClick={() => {}}
        href="http://pesquisa.doe.seplag.ce.gov.br/doepesquisa/"
        target="_blank"
        rel="noreferrer"
      >
        Diário oficial
      </a>
    </Menu.Item>
    <Menu.Item key="5" className="item-accessibility">
      <a
        onClick={() => {}}
        href="https://www.al.ce.gov.br/"
        target="_blank"
        rel="noreferrer"
      >
        Legislação estadual
      </a>
    </Menu.Item>
    <Menu.Item key="6" className="item-accessibility">
      <a
        onClick={() => {}}
        href="https://www.ceara.gov.br/governo/"
        target="_blank"
        rel="noreferrer"
      >
        Ações de governo
      </a>
    </Menu.Item>
  </Menu>
);

const AccessibilityBar = () => {
  getDefaultContrast();
  getDefaultFontSize();

  useEffect(() => {
    // seleciona o nó alvo
    const target = document.querySelector('#root');
    // cria uma nova instância de observador
    const observer = new MutationObserver(() => {
      getDefaultFontSize();
    });

    // configuração do observador:
    const config = { childList: true, subtree: true, attributes: true };

    // passar o nó alvo, bem como as opções de observação
    observer.observe(target, config);
  });
  return (
    <div className="accessibility-bar">
      <div className="accessibility-content">
        <ul>
          <li>
            <a
              href="https://www.ceara.gov.br/"
              target="_blank"
              rel="noreferrer"
              className="item-accessibility"
            >
              <FormattedMessage id="governmentPortal" />
            </a>
          </li>
          <li>
            <a
              href="https://www.pefoce.ce.gov.br/"
              target="_blank"
              rel="noreferrer"
              className="item-accessibility"
            >
              <FormattedMessage id="pefoceHome" />
            </a>
          </li>
          {/* <li>
          <a
          href="https://cearatransparente.ce.gov.br/"
          target="_blank"
          rel="noreferrer"
          className="item-accessibility"
          >
          <FormattedMessage id="transparentCeara" />
          </a>
          </li>
        <li> */}
          {/* <li>
          <a
            href="https://cearatransparente.ce.gov.br/portal-da-transparencia/ouvidoria"
            target="_blank"
            rel="noreferrer"
            className="item-accessibility"
          >
            <FormattedMessage id="ombudsman" />
          </a>
        </li> */}
          <li className="accessibility-dropDown">
            <Dropdown overlay={menu} trigger={['click']}>
              <a onClick={(e) => e.preventDefault()}>
                <FormattedMessage id="othersSites" /> <DownOutlined />
              </a>
            </Dropdown>
          </li>
        </ul>
        <div className="accessibitity-actions">
          <Button
            className="accessibitity-action-button"
            onClick={setDefaultFontSize}
          >
            <img src={fontDefault} alt="Fonte padrão" />
          </Button>
          <Button
            className="accessibitity-action-button"
            onClick={decreaseFont}
          >
            <img src={fontLess} alt="Diminuir fonte" />
          </Button>
          <Button
            className="accessibitity-action-button"
            onClick={increaseFont}
          >
            <img src={fontPlus} alt="Aumentar fonte" />
          </Button>
          <Button
            className="accessibitity-action-button"
            onClick={toogleContrast}
          >
            <img src={contrast} alt="Alto contraste" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AccessibilityBar;
