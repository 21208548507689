import PropTypes from 'prop-types';
import { lazy, Suspense } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import Loader from './components/Loader/loader';

import api from './services/api';
import auth from './services/auth';
import rolesPath from './utils/roles';

//import to pages
const Login = lazy(() => import('./screens/Public/Login/login'));
const Register = lazy(() => import('./screens/Public/Register/register'));

const LandingPage = lazy(() =>
  import('./screens/Public/LandingPage/landingPage')
);
/* const RoleSelection = lazy(() =>
  import('./screens/Private/RoleSelection/roleSelection')
); */
const AuthenticityTerms = lazy(() =>
  import('./screens/Public/AuthenticityTerms/authenticityTerms')
);
const RecoveryPassword = lazy(() =>
  import('screens/Public/Password/Request/Form/requestForm')
);

const UpdatePassword = lazy(() =>
  import('screens/Public/Password/Recovery/Form/recoveryForm')
);

const Activation = lazy(() => import('./screens/Public/Activation/activation'));
const Validation = lazy(() => import('./screens/Public/Validation/validation'));
const Home = lazy(() => import('./screens/Private/User/Home/home'));
const Rectify = lazy(() => import('./screens/Private/User/Rectify/rectify'));
const RequestType = lazy(() =>
  import('./screens/Private/User/RequestType/requestType')
);
const RequestForm = lazy(() =>
  import('./screens/Private/User/RequestForm/requestForm')
);
const UserDashboard = lazy(() =>
  import('./screens/Private/User/Dashboard/dashboard')
);
const RequestToOther = lazy(() =>
  import('./screens/Private/User/RequestToOther/requestToOther')
);
const LocalSelection = lazy(() =>
  import('./screens/Private/User/LocalSelection/localSelection')
);
const Profile = lazy(() => import('screens/Private/User/Profile/profile'));

// Supervisor - ERG_SUPERVISOR
const SupervisorHome = lazy(() =>
  import('./screens/Private/Supervisor/Menu/menu')
);

// Analista - ERG_TERCEIRIZADO
const AnalystHome = lazy(() => import('./screens/Private/Analyst/Menu/menu'));
const AnalystPassword = lazy(() =>
  import('./screens/Private/Analyst/Password/password')
);
const Solicitations = lazy(() =>
  import('./screens/Private/Commons/Solicitations/solicitations')
);
const Solicitation = lazy(() =>
  import('./screens/Private/Commons/Solicitation/solicitation')
);
const AttendanceList = lazy(() =>
  import('./screens/Private/Analyst/Attendance/List/list')
);
const AttendanceData = lazy(() =>
  import('./screens/Private/Analyst/Attendance/Data/data')
);
const AttendanceAnthropometry = lazy(() =>
  import('./screens/Private/Analyst/Attendance/Anthropometry/anthropometry')
);
const Checkins = lazy(() =>
  import('./screens/Private/Analyst/Checkins/List/list')
);
const CheckinsData = lazy(() =>
  import('./screens/Private/Analyst/Checkins/Data/data')
);

// Admin - ERG_ADMIN
const AdminHome = lazy(() => import('./screens/Private/Admin/Menu/menu'));
const AdminPassword = lazy(() =>
  import('./screens/Private/Admin/Password/password')
);
const LocationManager = lazy(() =>
  import('./screens/Private/Admin/Location/list')
);
const LocationRegister = lazy(() =>
  import('./screens/Private/Admin/Location/Register/register')
);
const LocationData = lazy(() =>
  import('./screens/Private/Admin/Location/Data/data')
);
const Scheduler = lazy(() =>
  import('./screens/Private/Admin/Scheduler/List/list')
);
const SchedulerRegister = lazy(() =>
  import('./screens/Private/Admin/Scheduler/Register/register')
);
const UsersList = lazy(() => import('./screens/Private/Admin/Users/list'));
const UsersData = lazy(() => import('./screens/Private/Admin/Users/Data/data'));

const PrivacyPolicy = lazy(() =>
  import('screens/Public/PrivacyPolicy/privacyPolicy')
);
const ListAttendance = lazy(() =>
  import('screens/Private/ListAttendance/listAttendance')
);
const AdminDashboard = lazy(() =>
  import('screens/Private/Admin/Dashboard/dashboard')
);

function getPathUser(role) {
  const array = [];
  rolesPath.forEach((elem) => {
    if (elem.role.includes(role)) {
      array.push(elem.path);
    }
  });
  return array;
}

async function check() {
  let role = auth.getRole();
  let navigate = useNavigate();

  try {
    const response = await api.get(`auth/checkRole/${role}`);
    if (response.data !== true) {
      auth.logout().then(() => {
        navigate('/login');
      });
    }
  } catch {
    auth
      .logout()
      .then(() => {
        navigate('/login');
      })
      .catch(() => {
        navigate('/login');
      });
  }
}

const PrivateRoute = ({ children }) => {
  let navigate = useNavigate();
  const { pathname } = useLocation();

  if (auth.getUser()) check();

  if (auth.getRole()) {
    let allowedRoutes = getPathUser(auth.getRole());

    if (auth.isAuthenticated() && allowedRoutes.includes(pathname)) {
      return children;
    } else if (allowedRoutes.length > 0) {
      return <Navigate to={allowedRoutes[0]} replace />;
    } else if (allowedRoutes.length === 0) {
      auth
        .logout()
        .then(() => {
          navigate('/login');
        })
        .catch(() => {
          navigate('/login');
        });
    } else return <Navigate to="/" replace />;
  } else {
    return <Navigate to="/" replace />;
  }
};

export const RoutesComponent = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        {/* <Route path="/role/selection" element={<RoleSelection />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        {/* <Route path="/register/:type/finish/:code" element={<Activation />} /> */}
        <Route exact path="/terms-of-use" element={<PrivacyPolicy />} />
        <Route exact path="/recovery/password" element={<RecoveryPassword />} />

        <Route
          exact
          path="/update/password/:code"
          element={<UpdatePassword />}
        />
        <Route exact path="/profile" element={<Profile />} />
        {/*  <Route exact path="/editprofile" element={<EditProfile />} />
        <Route exact path="/address" element={<Address />} /> */}
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/rectify"
          element={
            <PrivateRoute>
              <Rectify />
            </PrivateRoute>
          }
        />
        <Route
          path="/request-type"
          element={
            <PrivateRoute>
              <RequestType />
            </PrivateRoute>
          }
        />
        <Route
          path="/request-form"
          element={
            <PrivateRoute>
              <RequestForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <UserDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/local/selection"
          element={
            <PrivateRoute>
              <LocalSelection />
            </PrivateRoute>
          }
        />

        <Route
          path="/request-to-other"
          element={
            <PrivateRoute>
              <RequestToOther />
            </PrivateRoute>
          }
        />
        <Route
          path="/analyst"
          element={
            <PrivateRoute>
              <AnalystHome />
            </PrivateRoute>
          }
        />
        <Route
          path="/analyst/auth/password"
          element={
            <PrivateRoute>
              <AnalystPassword />
            </PrivateRoute>
          }
        />
        <Route
          path="/analyst/attendance"
          element={
            <PrivateRoute>
              <AttendanceList />
            </PrivateRoute>
          }
        />
        <Route
          path="/analyst/attendance/data"
          element={
            <PrivateRoute>
              <AttendanceData />
            </PrivateRoute>
          }
        />
        <Route
          path="/analyst/attendance/anthropometry"
          element={
            <PrivateRoute>
              <AttendanceAnthropometry />
            </PrivateRoute>
          }
        />
        <Route
          path="/analyst/checkin"
          element={
            <PrivateRoute>
              <Checkins />
            </PrivateRoute>
          }
        />
        <Route
          path="/analyst/checkin/data"
          element={
            <PrivateRoute>
              <CheckinsData />
            </PrivateRoute>
          }
        />
        <Route
          path="/supervisor"
          element={
            <PrivateRoute>
              <SupervisorHome />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <PrivateRoute>
              <AdminHome />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/auth/password"
          element={
            <PrivateRoute>
              <AdminPassword />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/location/data"
          element={
            <PrivateRoute>
              <LocationData />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/location/list"
          element={
            <PrivateRoute>
              <LocationManager />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/location/register"
          element={
            <PrivateRoute>
              <LocationRegister />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/users/list"
          element={
            <PrivateRoute>
              <UsersList />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/users/data"
          element={
            <PrivateRoute>
              <UsersData />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/scheduler"
          element={
            <PrivateRoute>
              <Scheduler />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/scheduler/register"
          element={
            <PrivateRoute>
              <SchedulerRegister />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/dashboard"
          element={
            <PrivateRoute>
              <AdminDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/list-attendance"
          element={
            <PrivateRoute>
              <ListAttendance />
            </PrivateRoute>
          }
        />
        <Route
          path="/consultation"
          element={
            <PrivateRoute>
              <Solicitations />
            </PrivateRoute>
          }
        />
        <Route
          path="/consultation/data"
          element={
            <PrivateRoute>
              <Solicitation />
            </PrivateRoute>
          }
        />
        <Route path="/register/:type/finish/:code" element={<Activation />} />
        <Route path="/validation/sso/:code" element={<Validation />} />
        <Route path="/authenticity-terms" element={<AuthenticityTerms />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RoutesComponent;
