import { getLocalStorage, setLocalStorage } from './utils';

let enableContrast = false;
let accessibilities = getLocalStorage('accessibilitiesConfig');

const minSizeFont = 10;
const maxSizeFont = 20;
let defaultFontSize = 0;
const listElements =
  'h1, h2, h3, h4, h5, h6, p, label, a, input, em, li, table, table a, table div, div > span, .data-item > div, .ant-tabs-tab > div, .info-item, .actions > button > span, ul > li, .ant-menu-item  > a > span, button > span, h3.ant-typography, div, span, tr, td, th, button';
let existsFontSize = accessibilities?.fontSize;
let values_defaults = [];

export function getDefaultContrast() {
  const contrast = accessibilities?.contrast ?? false;
  const body = document.querySelector('body');

  if (contrast === true) {
    body.classList.toggle('contrast');
    enableContrast = true;
  } else {
    body.classList.remove('contrast');
  }
}

export function toogleContrast() {
  const body = document.querySelector('body');
  body.classList.toggle('contrast');
  enableContrast = !enableContrast;
  accessibilities = {
    contrast: enableContrast,
    fontSize: defaultFontSize,
  };
  setLocalStorage('accessibilitiesConfig', accessibilities);
}

export function getDefaultFontSize() {
  accessibilities = getLocalStorage('accessibilitiesConfig');
  existsFontSize = accessibilities?.fontSize;
  defaultFontSize = accessibilities?.fontSize ?? 14;
  values_defaults = [];
  const elements = document.body.querySelectorAll(listElements);

  if (existsFontSize != null) {
    if (defaultFontSize <= maxSizeFont) {
      elements.forEach((element) => {
        const style = window
          .getComputedStyle(element, null)
          .getPropertyValue('font-size');
        const fontSize = parseFloat(style);
        const index_val = element.localName;

        values_defaults.push({ tagname: index_val, fontsize: fontSize });

        if (fontSize <= maxSizeFont) {
          element.style.fontSize = `${defaultFontSize}px`;
        }
      });
    }
  } else {
    elements.forEach((element) => {
      const style = window
        .getComputedStyle(element, null)
        .getPropertyValue('font-size');
      const fontSize = parseFloat(style);
      const index_val = element.localName;

      values_defaults.push({ tagname: index_val, fontsize: fontSize });
    });
  }
}

export function increaseFont() {
  const elements = document.body.querySelectorAll(listElements);

  if (defaultFontSize < maxSizeFont) {
    elements.forEach((element) => {
      const style = window
        .getComputedStyle(element, null)
        .getPropertyValue('font-size');
      const fontSize = parseFloat(style);

      if (fontSize < maxSizeFont) {
        element.style.fontSize = `${fontSize + 1}px`;
      }
    });
    defaultFontSize++;

    accessibilities = {
      contrast: enableContrast,
      fontSize: defaultFontSize,
    };
    setLocalStorage('accessibilitiesConfig', accessibilities);
  }
}

export function decreaseFont() {
  const elements = document.body.querySelectorAll(listElements);

  if (defaultFontSize >= minSizeFont) {
    elements.forEach((element) => {
      const style = window
        .getComputedStyle(element, null)
        .getPropertyValue('font-size');
      const fontSize = parseFloat(style);

      if (fontSize >= minSizeFont) {
        element.style.fontSize = `${fontSize - 1}px`;
      }
    });

    defaultFontSize--;

    accessibilities = {
      contrast: enableContrast,
      fontSize: defaultFontSize,
    };
    setLocalStorage('accessibilitiesConfig', accessibilities);
  }
}

export function setDefaultFontSize() {
  defaultFontSize = 0;
  const elements = document.body.querySelectorAll(listElements);
  elements.forEach((element, index) => {
    if (element.localName === values_defaults[index].tagname) {
      const fontSizeElement = values_defaults[index].fontsize;
      element.setAttribute(
        'style',
        `font-size: ${fontSizeElement} !important;`
      );
    }
  });

  accessibilities = {
    contrast: enableContrast,
    fontSize: undefined,
  };
  setLocalStorage('accessibilitiesConfig', accessibilities);
}
