export default function users(
  state = {
    name: '',
    img: '',
    email: '',
    phone: '',
    active: false,
    internal: false,
    externalId: '',
    cpf: '',
  },
  action
) {
  switch (action.type) {
    case 'HEADER_CONTROL':
      return {
        ...state,
      };
    case 'SET_USER':
      return {
        ...state,
        name: action.payload[0],
        email: action.payload[1],
        phone: action.payload[2],
        img: action.payload[3],
        externalId: action.payload[4],
        cpf: action.payload[5],
      };
    case 'INTERNAL':
      return {
        ...state,
        internal: action.payload,
      };
    case 'USER_ACTIVE':
      return {
        ...state,
        active: action.payload,
      };
    case 'OFF':
      return {
        name: '',
        img: '',
        email: '',
        phone: '',
        active: false,
        internal: false,
        externalId: '',
      };
    default:
      return state;
  }
}
