import { Dropdown, Menu, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as usersActions from '~/actions/users';
import img from '~/assets/img/ce.png';
import auth from '~/services/auth';

import './dropdown.scss';

const DropdownMenu = ({ users }) => {
  const roles = auth.getUser();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      roles &&
      (roles.includes('ERG_TERCEIRIZADO') ||
        roles.includes('ERG_ADMIN') ||
        roles.includes('ERG_SUPERVISOR'))
    ) {
      dispatch(usersActions.internal(true));
    }
  }, []);

  const logout = async () => {
    setLoading(true);
    auth.logout();
    dispatch(usersActions.userActive(false));
    dispatch(usersActions.internal(false));
    dispatch(usersActions.off());
    localStorage.removeItem('ERG');
    navigate('/login');
    setLoading(false);
  };

  const menu = (
    <Menu triggerSubMenuAction="click" className="menu-profile">
      <Menu.Item key="0" className="menu-info name">
        {users.name}
      </Menu.Item>
      <Menu.Item key="1" className="menu-info email">
        {users.email}
      </Menu.Item>
      <Menu.Divider />
      {/* {users.internal && roles.length > 1 ? (
        roles.length === 2 &&
        roles.includes('ERG_SOLICITANTE') &&
        roles.includes('IC_USER') ? null : (
          <SubMenu
            key="5"
            className="submenu-item"
            title={`Perfil selecionado: ${usersName[auth.getRole()]}`}
          >
            {rolesList.map((role) => {
              if (usersName[role]) {
                return (
                  <Menu.Item
                    key={role}
                    value={role}
                    disabled={auth.getRole() === role ? true : false}
                    onClick={(e) => handleClick(e.key)}
                  >
                    {usersName[role]}
                  </Menu.Item>
                );
              }
            })}
          </SubMenu>
        )
      ) : null} */}
      <Menu.Divider />
      {!users.internal && (
        <Menu.Item key="3" className="menu-item">
          <a onClick={() => navigate('/profile')}>Editar perfil</a>
        </Menu.Item>
      )}
      <Menu.Item key="4" className="menu-item">
        <a onClick={() => logout()}>Sair</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="dropdown-container">
        <Dropdown overlay={menu} trigger={['click']} className="dropdown">
          <span className="ant-dropdown-link dropdown-button">
            {users.name && (
              <div className="dropdown-greeting">
                Olá, {users.name.split(' ')[0]}!
              </div>
            )}
            <div className="dropdown-img">
              <img src={img} alt="" />
            </div>
          </span>
        </Dropdown>
        {loading && (
          <div className="logout-loading">
            <Spin />
          </div>
        )}
      </div>
    </>
  );
};

DropdownMenu.propTypes = {
  users: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  users: state.users,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(usersActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DropdownMenu);
