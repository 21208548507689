import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';

import { flattenMessages } from './utils/utils';
import pt_BR from 'antd/lib/locale/pt_BR';
import messages from './i18n/messages';
import store from './store';
import Routes from './routes';

import Header from './components/UI/Header/header';
import Footer from './components/UI/Footer/footer';
import VLibras from './components/UI/VLibras/vLibras';
import locale from './helpers/locale';
import AccessibilityBar from './components/UI/AccessibilityBar/accessibilityBar';

import './styles/contrast.scss';
import './styles/global.scss';

if (locale) {
  locale === 'pt-BR'
    ? require('moment/locale/pt-br')
    : require('moment/locale/en-gb');
}

export default function App() {
  return (
    <Provider store={store}>
      <ConfigProvider locale={pt_BR}>
        <IntlProvider
          locale={'pt'}
          messages={flattenMessages(messages[locale])}
        >
          <BrowserRouter
            basename={process.env.REACT_APP_BASENAME_BROWSER_HISTORY}
          >
            <div className="container">
              <AccessibilityBar />
              <div className="app-container">
                <Header />
                <div className="app-content">
                  <Routes />
                </div>
                <Footer />
              </div>
              <VLibras />
            </div>
          </BrowserRouter>
        </IntlProvider>
      </ConfigProvider>
    </Provider>
  );
}
