/* eslint-disable no-unused-vars */
import axios from 'axios';
import auth from './auth';
import * as usersActions from '~/actions/users';

const api = axios.create({
  baseURL: process.env.REACT_APP_AUTH_BASE_URL,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(async (config) => {
  const storedAuth = localStorage.getItem('ERG');
  if (storedAuth) {
    const { token } = JSON.parse(storedAuth);
    if (token) {
      config.headers.Authorization = token;
    }
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.message.includes('Network Error')) {
      auth.logout();
      usersActions.userActive(false);
      usersActions.internal(false);
      usersActions.off();
      history.go('/login');
    } else if (
      error.response &&
      (error.response.status === 500 ||
        error.response.status === 403 ||
        error.message.includes('Network Error'))
    ) {
      auth.logout();
      usersActions.userActive(false);
      usersActions.internal(false);
      usersActions.off();
      history.go('/login');
    }
    return Promise.reject(error);
  }
);

export default api;
