export function headerControl(payload) {
  return {
    type: 'HEADER_CONTROL',
    payload,
  };
}

export function setUser(payload) {
  return {
    type: 'SET_USER',
    payload,
  };
}

export function internal(payload) {
  return {
    type: 'INTERNAL',
    payload,
  };
}

export function userActive(payload) {
  return {
    type: 'USER_ACTIVE',
    payload,
  };
}

export function off() {
  return {
    type: 'OFF',
  };
}
