export default {
  'database.exception': 'Error trying to recover information from database.',
  'auth.login.fail': 'The login process failed.',
  'auth.login.inactive':
    'The login process is inactive. Contact the system admin.',
  'auth.login.blocked':
    'The login process is blocked. Contact the system admin.',
  'auth.login.canceled':
    'The login process is cancelled. Contact the system admin.',
  'token.sso.find.notfound': 'Token SSO not found',
  requiredEmail: 'Please, input your e-mail.',
  typeEmail: 'This e-mail is not valid.',
  requiredPassword: 'Please, input your password.',
  requiredMinPassword: 'Password must be at least 6 characters',
  requiredMinPasswordLogin: 'Your password is at least 6 characters long',
  passwordConfirm: 'Password Confirmation',
  password: 'Password',
  loginButton: 'LOGIN',
  loginQrCode: 'Login with QRCode',
  fullName: 'Full Name',
  phone: 'Phone',
  email: 'Email',
  requiredName: 'Please, input your full name.',
  requiredPhone: 'Please, input your phone number.',
  passwordUpdateButton: 'UPDATE',
  maximumCharactersName: 'Name must have a maximum of 150 characters.',
  maximumCharactersEmail: 'E-mail must have a maximum of 60 characters.',
  sessionExpired: 'Session expired!',
  logout: 'Log out',
  name: 'Name',
  roles: 'Roles',
  save: 'Save',
  unexpectedError: 'Unexpected error',
  networkError: 'Network Error',
  noResponse: 'Unable to get response for your request',
  accessibility: 'Accessibility',
  governmentPortal: 'Government Portal',
  civilHome: 'Civil Home',
  pefoceHome: 'PEFOCE Home',
  ombudsman: 'Ombudsman',
  othersSites: 'Others sites',
  titleRegister: 'Register Form',
  cpf: 'CPF',
  register: 'REGISTER',
  passwordRequestText: 'Password Recovery',
  passwordRequestButton: 'SEND',
  passwordUpdateText: 'Password Update',
  signup: 'Sign Up',
  recoveryPassword: 'Forgot my password',
  invalidCpf: 'Invalid CPF',
  notProvidedCpf: 'Not Provided',
  formatCpf: 'Please, input valid CPF.',
  requiredCpf: 'Please, input your CPF.',
  address: 'Address',
  valid: 'Valid',
  invalid: 'Invalid',
  pending: 'Pending',
  conflict: 'Conflict',
  zipCode: 'ZIP Code',
  street: 'Street',
  district: 'District',
  number: 'Number',
  city: 'City',
  state: 'State',
  adjunct: 'Adjunct',
  termsOfUse: 'Terms of Use',
  agree: 'Agree with the',
  flag: 'Flag',
  requiredMin6: 'The name must have at least 6 characters',
  errorPhoneNumber: 'Incorrect phone number',
  personalData: 'Personal data',
  editProfile: 'Edit profile',
  differentPassword: 'Diferent password',
  registerSuccessMessage: 'Register Success',
  updateSuccessMessage: 'Update sucess',
};
