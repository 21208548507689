import { useSelector } from 'react-redux';

import QuickAccess from '~/components/UI/QuickAccess/quickAccess';

import facebook from 'assets/img/footer/facebook.png';
import instagram from 'assets/img/footer/instagram.png';
import twitter from 'assets/img/footer/twitter.png';

import './footer.scss';

const Footer = () => {
  const applicationVersion =
    process.env.REACT_APP_APPLICATION_VERSION || 'v0.0.0';
  const applicationYear = new Date().getUTCFullYear();

  const user = useSelector((state) => state.users);

  return (
    <>
      {!user.internal && <QuickAccess />}
      <footer className="footer footer-and-header-padding">
        <div className="content">
          {/* <a className="link" href="https://www.idceara.ce.gov.br/emissao/">
            <h2>IDCEARA.CE.GOV.BR/EMISSAO/</h2>
          </a> */}
          {!user.internal && (
            <div className="social-medias-content">
              <h2>Nossos canais</h2>
              <ul className="social-medias">
                <li className="facebook">
                  <a
                    href="https://www.facebook.com/governodoceara"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={facebook} alt="Facebook" />
                  </a>
                </li>
                <li className="instagram">
                  <a
                    href="https://www.instagram.com/governodoceara/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={instagram} alt="Instagram" />
                  </a>
                </li>
                <li className="twitter">
                  <a
                    href="https://twitter.com/governodoceara"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={twitter} alt="Twitter" />
                  </a>
                </li>
              </ul>
            </div>
          )}
          <div className="description">
            <div>
              {applicationVersion} {applicationYear} &copy; Governo do Estado do
              Ceará.
            </div>
            Todos os direitos reservados
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
