export function flattenMessages(nestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    let value = nestedMessages[key];
    let prefixedKey = prefix ? `${prefix}.${key}` : key;
    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }
    return messages;
  }, {});
}

export function validateCpf(rule, value) {
  try {
    if (!value) {
      return Promise.resolve();
    }
    let cpfInput = value;
    let cpfNumber = cpfInput.replace(/\D/g, '');
    if (cpfNumber !== '') {
      let validate = /^[0-9]{11}$/;
      if (validate.test(cpfNumber)) {
        if (
          cpfNumber === '00000000000' ||
          cpfNumber === '11111111111' ||
          cpfNumber === '22222222222' ||
          cpfNumber === '33333333333' ||
          cpfNumber === '44444444444' ||
          cpfNumber === '55555555555' ||
          cpfNumber === '66666666666' ||
          cpfNumber === '77777777777' ||
          cpfNumber === '88888888888' ||
          cpfNumber === '99999999999'
        )
          return Promise.reject('CPF inválido');
        let sum;
        let rest;
        sum = 0;

        for (let i = 0; i <= 9; i++) {
          sum = sum + cpfNumber.substring(i - 1, i) * (11 - i);
        }
        rest = (sum * 10) % 11;

        if (rest === 10 || rest === 11) {
          rest = 0;
        }
        if (rest !== parseInt(cpfNumber.substring(9, 10))) {
          return Promise.reject('CPF inválido');
        }

        sum = 0;
        for (let i = 1; i <= 10; i++) {
          sum = sum + cpfNumber.substring(i - 1, i) * (12 - i);
        }
        rest = (sum * 10) % 11;
        if (rest === 10 || rest === 11) {
          rest = 0;
        }
        if (rest !== parseInt(cpfNumber.substring(10, 11))) {
          return Promise.reject('CPF inválido');
        }
      }
    }
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
}

export const capitalize = (str) => {
  if (typeof str !== 'string') {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.substr(1);
};

export const setMaskCEP = (value) => {
  let text = value.replace(/D/g, '');
  text = value.replace(/^(\d{5})(\d)/, '$1-$2');
  return text;
};

export const formatDate = (value) => {
  var datePart = value.match(/\d+/g),
    year = datePart[0],
    month = datePart[1],
    day = datePart[2];

  return day + '/' + month + '/' + year;
};

export const validateRG = (_, string) => {
  try {
    const regex = /^(\d+)-(\d)$/;
    const match = string.match(regex);
    if (match) {
      return Promise.resolve();
    } else if (string.indexOf('-') === -1) {
      return Promise.resolve();
    } else if (/^[0-9-]+$/.test(string) === false) {
      return Promise.reject('RG inválido');
    } else if (string.split('-').length > 2) {
      return Promise.reject('RG inválido');
    } else {
      return Promise.reject('RG inválido');
    }
  } catch (err) {
    return Promise.reject('RG inválido');
  }
};

export const extractRG = (string) => {
  const regex = /^(\d+)-(\d)$/;
  const match = string.match(regex);

  if (match) {
    const number = match[1];
    const verifyingDigit = match[2];
    return {
      number: number,
      verifyingDigit: verifyingDigit,
    };
  } else if (string.indexOf('-') === -1) {
    return {
      number: string,
      verifyingDigit: null,
    };
  } else if (/^[0-9-]+$/.test(string) === false) {
    return {
      number: null,
      verifyingDigit: null,
      erro: 'RG inválido.',
    };
  } else if (string.split('-').length > 2) {
    return {
      number: null,
      verifyingDigit: null,
      erro: 'RG inválido.',
    };
  } else {
    return {
      number: null,
      verifyingDigit: null,
      erro: 'RG inválido.',
    };
  }
};

export const concatString = (value1, value2, type) => {
  if (value2) {
    return value1.concat(type ?? '-', value2);
  }

  return value1;
};

export function getLocalStorage(element) {
  return JSON.parse(localStorage.getItem(element));
}

export function setLocalStorage(element, value) {
  return localStorage.setItem(element, JSON.stringify(value));
}
