import './quickAccess.scss';

const QuickAccess = () => {
  return (
    <div className="quick-access footer-and-header-padding">
      <h4>Acesso rápido</h4>
      <ul className="quick-access-links">
        <li>
          <a
            href="https://cearatransparente.ce.gov.br/"
            target="_blank"
            rel="noreferrer"
          >
            <i id="transparentCeara"></i>
            Ceará Transparente
          </a>
        </li>
        <li>
          <a
            href="https://cartadeservicos.ce.gov.br/ConsultaCesec/pg_cs_servico.aspx"
            target="_blank"
            rel="noreferrer"
          >
            <i id="serviceLetter"></i>
            Carta de serviços do cidadão
          </a>
        </li>
        <li>
          <a
            href="https://cearatransparente.ce.gov.br/portal-da-transparencia/acesso-a-informacao?locale=pt-BR"
            target="_blank"
            rel="noreferrer"
          >
            <i id="ldpg"></i>
            Lei geral de acesso à informação
          </a>
        </li>
        <li>
          <a
            href="http://pesquisa.doe.seplag.ce.gov.br/doepesquisa/"
            target="_blank"
            rel="noreferrer"
          >
            <i id="officialDiary"></i>
            Diário oficial
          </a>
        </li>
        <li>
          <a href="https://www.al.ce.gov.br/" target="_blank" rel="noreferrer">
            <i id="staleLaw"></i>
            Legislação estadual
          </a>
        </li>
        <li>
          <a
            href="https://www.ceara.gov.br/governo/"
            target="_blank"
            rel="noreferrer"
          >
            <i id="governmentActions"></i>
            Ações de governo
          </a>
        </li>
      </ul>
    </div>
  );
};

export default QuickAccess;
