import { notification, Button } from 'antd';

import errorMessages from '~/utils/errorMessages.json';

import '../styles/notification.scss';

const closeAllNotifications = () => {
  notification.destroy();
};

export default function Notification(type, message, status) {
  if (status !== 404)
    notification[type]({
      message: errorMessages[message] ? errorMessages[message] : message,
      duration: 0,
      placement: 'top',
      btn: <Button onClick={closeAllNotifications}>Limpar todas</Button>,
    });
}

export function ConfirmNotification(type, message, onClickFunction) {
  notification[type]({
    message: errorMessages[message] ? errorMessages[message] : message,
    duration: 0,
    placement: 'top',
    btn: <Button onClick={onClickFunction}>Confirmar</Button>,
  });
}

export function notificationFileSize(size) {
  if (!(size / 1024 / 1024 < 10)) {
    Notification('error', 'O tamanho do arquivo deve ser menor que 10MB.');
    return false;
  } else if (size / 1024 / 1024 === 0) {
    Notification('error', 'O arquivo não pode ser vazio.');
    return false;
  }
  return true;
}

export function notificationFileType(type) {
  if (
    type !== 'image/png' &&
    type !== 'image/jpg' &&
    type !== 'image/jpeg' &&
    type !== 'application/pdf'
  ) {
    Notification(
      'error',
      'Tipo de arquivo não permitido. O arquivo deve ser do tipo: jpeg, jpg, png, ou pdf.'
    );
    return false;
  }
  return true;
}

export const getErrors = (response) => {
  if (response?.errorFields) {
    for (let error in response?.errorFields) {
      if (response?.errorFields[error] !== undefined) {
        if (response?.errorFields[error]?.errors[error] !== undefined) {
          Notification(
            'error',
            response?.errorFields[error]?.errors[error],
            response.status
          );
        }
      }
    }
  } else if (response?.data?.errorFields) {
    for (let error in response?.data?.errorFields) {
      if (response?.data?.errorFields[error] !== undefined) {
        Notification(
          'error',
          response?.data?.errorFields[error]?.key,
          response.status
        );
      }
    }
  } else {
    Notification('error', response?.data?.key, response.status);
  }
};
