export default {
  'auth.login.canceled': 'O login foi cancelado. Entre em contato com o admin.',
  'auth.login.blocked': 'O login está bloqueado. Entre em contato com o admin.',
  'auth.login.inactive': 'O login está inativo. Entre em contato com o admin.',
  'database.application.notfound.appid':
    'O atributo app_id está inválido no sistema',
  'auth.login.fail':
    'Não conseguimos entrar na sua conta. Por favor, acesse sua conta do ID Ceará para validação do cadastro',
  'database.exception': 'Erro recuperando informação do banco de dados.',
  'token.sso.find.notfound': 'Token SSO não encontrado',
  cpf: 'CPF',
  invalidCpf: 'CPF inválido',
  notProvidedCpf: 'Não fornecido',
  formatCpf: 'Por favor, digite um CPF válido.',
  requiredCpf: 'Por favor, digite seu CPF.',
  requiredEmail: 'Por favor, digite seu e-mail.',
  typeEmail: 'Esse e-mail não é válido.',
  requiredPassword: 'Por favor, digite sua senha.',
  requiredMinPassword: 'A senha deve ter no minimo 6 caracteres',
  requiredMinPasswordLogin: 'Sua senha tem no minimo 6 caracteres',
  password: 'Senha',
  loginButton: 'ENTRAR',
  loginQrCode: 'ENTRAR COM QR CODE',
  fullName: 'Nome Completo',
  phone: 'Telefone',
  email: 'Email',
  requiredName: 'Por favor, digite seu nome completo.',
  requiredPhone: 'Por favor, digite seu número de telefone.',
  passwordUpdateButton: 'ATUALIZAR',
  maximumCharactersEmail: 'O e-mail deve ter no máximo 60 caracteres.',
  maximumCharactersName: 'O nome deve ter no máximo 150 caracteres.',
  sessionExpired: 'Sessão expirada!',
  logout: 'Sair',
  name: 'Nome',
  roles: 'Papéis',
  save: 'Salvar',
  unexpectedError: 'Erro inesperado',
  networkError: 'Erro de rede',
  noResponse: 'Não foi possível obter resposta para sua solicitação',
  accessibility: 'Acessibilidade',
  governmentPortal: 'Portal do Governo',
  pefoceHome: 'PEFOCE',
  civilHome: 'Casa Civil',
  ombudsman: 'Ouvidoria',
  othersSites: 'Mais sites',
  titleRegister: 'Formulário de Cadastro',
  register: 'CADASTRAR',
  passwordRequestText: 'Recuperação de Senha',
  passwordRequestButton: 'ENVIAR',
  passwordUpdateText: 'Atualização de Senha',
  signup: 'Cadastre-se',
  passwordConfirm: 'Confirmação da Senha',
  recoveryPassword: 'Esqueceu a senha?',
  termsOfUse: 'Termos de Uso',
  address: 'Endereço',
  valid: 'Válido',
  invalid: 'Inválido',
  pending: 'Pendente',
  conflict: 'Conflito',
  zipCode: 'CEP',
  street: 'Logradouro',
  district: 'Bairro',
  number: 'Número',
  city: 'Cidade',
  state: 'Estado',
  adjunct: 'Complemento',
  agree: 'Concordo com os',
  errorPhoneNumber: 'Número de telefone incorreto',
  requiredMin6: 'O nome deve ter no mínimo 6 caracteres',
  flag: 'Bandeira',
  personalData: 'Dados pessoais',
  editProfile: 'Editar perfil',
  differentPassword: 'Senha diferente',
  registerSuccessMessage: 'Registrado com sucesso!',
  updateSuccessMessage: 'Atualizado com sucesso!',
};
