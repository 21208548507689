/* eslint-disable no-unused-vars */
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import * as usersActions from '~/actions/users';

import DropdownMenu from '~/components/UI/Dropdown/dropdown';
import api from '~/services/api';
import auth from '~/services/auth';
import notification from '~/utils/notification.js';

import img from 'assets/img/header/pefoce-orgao.svg';

import governmentPortal from 'assets/img/header/icone-informacoes.png';
import ombudsman from 'assets/img/header/icone-ouvidoria.png';
import transparentCeara from 'assets/img/header/icone-transparencia.png';

import './header.scss';

const Header = () => {
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    auth
      .logout()
      .then(() => {
        dispatch(usersActions.userActive(false));
        dispatch(usersActions.internal(false));
        dispatch(usersActions.off());
      })
      .catch(() => {
        dispatch(usersActions.userActive(false));
        dispatch(usersActions.internal(false));
        dispatch(usersActions.off());
      });
    localStorage.removeItem('ERG');
    navigate('/login');
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const userResponse = await api.get('/auth/me');
        const { data } = userResponse;

        if (userResponse) {
          dispatch(
            usersActions.setUser([
              data?.name,
              data?.email,
              data?.telefone,
              null,
              data?.id,
              data?.cpf,
            ])
          );
          dispatch(usersActions.userActive(true));
        }
      } catch (error) {
        const { response } = error;
        notification('error', 'Erro de rede', response.status);
        if (auth.getUser()) logout();
      }
    }

    if (auth.isAuthenticated()) {
      fetchData();
    } else {
      dispatch(usersActions.userActive(false));
      dispatch(usersActions.internal(false));
      dispatch(usersActions.off());
    }
  }, [users.active, dispatch, auth.isAuthenticated()]);

  return (
    <div className="header">
      <div
        className={`main footer-and-header-padding ${
          !users.internal && 'with-height'
        }`}
      >
        <div className="bg-header">
          <Link className="brand" to="/">
            <h1 className="service-name"> CARTEIRA DE IDENTIDADE NACIONAL</h1>
            <img src={img} alt="Ceará - Governo do Estado" />
          </Link>
        </div>
        <div className="bg-header-overlay">
          <div className="footer-and-header-padding">
            {/* {users.active && auth.getRole() === 'ERG_SOLICITANTE' && <Help />} */}
            {users.active && <DropdownMenu />}
            {!users.internal && !users.active && (
              <div className="header-actions">
                <ul className="header-links">
                  <li>
                    <a
                      href="https://cearatransparente.ce.gov.br/portal-da-transparencia/ouvidoria"
                      target="_blank"
                      rel="noreferrer"
                      className="item"
                    >
                      <img src={ombudsman} alt="Ouvidoria estadual" />
                      <span>Ouvidoria estadual</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://cearatransparente.ce.gov.br/"
                      target="_blank"
                      rel="noreferrer"
                      className="item"
                    >
                      <img src={transparentCeara} alt="Ceará Transparente" />
                      <span>Ceará Transparente</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.ceara.gov.br/"
                      target="_blank"
                      rel="noreferrer"
                      className="item"
                    >
                      <img src={governmentPortal} alt="Acesso à informação" />
                      <span>Acesso à informação</span>
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
