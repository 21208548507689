import { Layout } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

import './loader.scss';

const { Content } = Layout;

const Loader = () => {
  return (
    <Content className="content loader">
      <LoadingOutlined className="icon" />
    </Content>
  );
};

export default Loader;
